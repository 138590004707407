<template>
  <section id="card-holder">
    <div class="row q-mx-auto justify-start items-start">
      <div class="row q-gutter-md" v-if="this.items.length">
        <span
          :key="narrative.uuid"
          v-for="narrative in items"
          :id="narrative.uuid"
        >
          <card
            :title="narrative.title"
            :imageUrl="narrative.icon_url"
            :url="narrative.url"
            type="Narrative"
            :csstype="narrative.owner==user? 'private':'ViewpointCollection'"
            :btn-edit-modal="user === narrative.owner"
            @showEdit="showEdit(narrative)"
            @cardClicked="cardClick(narrative)"
            :btn-delete="user === narrative.owner"
            @deleteCard="remove(narrative.uuid)"
            :btn-publish="!narrative.is_visible"
            :btn-un-publish="narrative.is_visible"
            @publish="publish(narrative)"
            @unpublish="publish(narrative)"
          >
            {{ narrative.teaser }}
          </card>
        </span>
      </div>
      <div class="row q-gutter-md" v-else>No narratives found.</div>
    </div>
  </section>
</template>

<script>
import { mapGetters } from "vuex";
import { API } from "@/tic";
import Card from "../tags/Card";

export default {
  name: "NarrativesIndex",
  components: { Card },
  data() {
    return {
      items: [],
      current: null,
      action: "Add",
      toggleForm: false,
    };
  },

  mounted() {
    this.reload();
  },

  methods: {
    cardClick(card) {
      this.$router.push(`/narratives/${card.slug}`);
    },

    remove(uuid) {
      let vm = this;
      console.log("xx: ", uuid);
      API.post("/narrative/delete", {
        narrative: uuid,
        user: vm.$store.getters["user/uuid"],
      }).then(() => {
        vm.reload();
      });
    },

    publish(vp) {
      let vm = this;
      API.post("/narrative/toggle/visibility", {
        collection: narrative.uuid,
        session: vm.$store.getters["user/session"],
      }).then(() => {
        vm.reload();
      });
    },

    showEdit(e) {
      this.$router.push(`/narratives/${e.slug}/start/edit`);
    },

    addViewpoint() {
      this.current = {};
      this.action = "Add";
      this.toggleForm = true;
    },

    reload(toggle = false) {
      //this.$q.notify({ message: 'RELOADED', position: 'center' });
      return API.get("/narratives")
        .then((r) => {
          this.items = [...r.data.data];
          console.log("narratives: ", this.items);
        })
        .then(() => {
          this.toggleForm = toggle;
        });
    },
  },

  computed: {
    ...mapGetters("user", { isAdmin: "isAdmin", user: "uuid" }),
  },
};
</script>
